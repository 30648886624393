<template>
  <v-app>
    <v-card flat width="100%" height="100%" color="#e6e6f1" class="lot_login">
      <v-card flat width="480" class="login-wrap">
        <v-card-title class="pa-0 text-center">
          <h1>
            블록체인 기반<br>
            <strong>사내 추첨 시스템</strong>
          </h1>
          <div class="pt-4 d-block h-desc">써클아이디로 로그인하시기 바랍니다</div>
        </v-card-title>
      </v-card>
    </v-card>
  </v-app>
</template>

<script>
// import jwtDecode from 'jwt-decode';

export default {
  name: 'EssLogin',
  components: {
  },
  data() {
    return {
      id: null,
      password: null,
      loading: false,
      CIRCLE_URL: null,
      // slo_p_ota: this.$route.params.slo_p_ota,
    };
  },
  created() {
    console.log(process.env);
    this.CIRCLE_URL = process.env.VUE_APP_CIRCLE_URL;
    this.BASE_URL = process.env.VUE_APP_BASE_URL;
    const sloUrl = `${this.CIRCLE_URL}?authType=1&method=get&destination=${this.BASE_URL}/web/login/slo`;
    window.location.href = sloUrl;
    console.info('auth ok');
    // console.log(this.$route.query.slo_p_ota);
  },
  methods: {
    go(path) {
      console.log(this.$route.path);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    login(path) {
      console.log(path);
      // this.$axios.post('/auth/authenticate', { userId: this.id, password: this.password }).then((res) => {
      // this.$axios.post('/neo/security_check', { j_username: this.id, j_password: this.password }).then((res) => {
      //   if (res.status === 200) {
      //     console.log(res.slo_p_ota);
      //     // this.token = res.data.token;
      //     // console.log(jwtDecode(this.token));
      //     // localStorage.setItem('userId', jwtDecode(this.token).sub);
      //     // localStorage.setItem('role', jwtDecode(this.token).auth);
      //     // localStorage.setItem('token', this.token);
      //     // console.log(localStorage.getItem('userId'));
      //     // this.go(path);
      //   }
      // });
    },
    submit() {
      console.log('submit');
    },
  },
};
</script>
